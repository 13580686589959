









import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        ProgressBar: () => import("@/components/layout/ProgressBar.vue"),
        InfoSnackbar: () => import("@/components/layout/InfoSnackbar.vue"),
        Footer: () => import("@/components/layout/Footer.vue")
    }
})
export default class MainView extends Vue {}
